@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
  height: 90vh;
}

h1,
h2,
h3,
h4,
h5,
button {
  font-family: "Bebas Neue", sans-serif;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .imageMain {
    width: 300px;
  }
  button {
    width: 100%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .imageMain {
    width: 500px;
  }
}

@media only screen and (max-width: 768px) {
  .serviceImage {
    justify-content: center;
  }
  .imageMain {
    margin-top: 20px;
  }
  .containerMainImage {
    justify-content: center;
  }

  .textMain {
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    
  }

  .containerPadding {
    margin-left: 0 !important;
    width: 100% !important;
  }


}


.formButton:disabled {
  background-color: gray !important;
}